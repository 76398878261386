import { useEffect, useState } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";
import { useAppLinkContext } from "../modules/useAppLink";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";

import { apiV2 } from "../common/api";

export enum TopBannerLocation {
  reservation = "reservation",
  serviceNote = "service_note",
  questionnaireResult = "questionnaire_result",
}

interface Banner {
  attr: {
    vendorCode: string;
    vendorName: string;
  };
  clickLink: string;
  code: string;
  imageUrl: string;
  trackLink: string;
}

SwiperCore.use([Autoplay]);

export default function TopBanner({
  location,
}: {
  location: TopBannerLocation;
}) {
  const [banners, setBanners] = useState<Banner[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await apiV2.get("/v1.0/events/house-banner");

        setBanners(data.sort(() => Math.random() - 0.5));
      } catch (e) {}
    })();
  }, []);

  const { openAppLink } = useAppLinkContext();
  const { trackEvent } = useAmplitudeTrack();

  return (
    <HFlex
      f-1
      style={{
        maxWidth: "480px",
        width: "100%",
      }}
    >
      <Swiper
        style={{
          width: "100%",
        }}
        slidesPerView={1}
        threshold={10}
        centeredSlides={true}
        loop={true}
        loopPreventsSlide={false}
        autoplay={{
          delay: 4000,
        }}
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.code}>
            <VFlex
              bc-cc
              style={{
                width: "100%",
                minHeight: "80px",
                backgroundImage: `url(${banner.imageUrl})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              onClick={() => {
                trackEvent(amplitudeEvents.marketplaceBannerClick, {
                  location,
                  vendorCode: banner.attr.vendorCode,
                  vendorName: banner.attr.vendorName,
                });
                openAppLink(`top_banner_${location}`);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </HFlex>
  );
}
