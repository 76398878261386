import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { apiV1 } from "../../common/api";

import _ from "lodash";
import { format } from "date-fns";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";

import SvgIcon, { SvgIconType } from "../../svg";
import TopBanner, { TopBannerLocation } from "../../components/TopBanner";

export default function QuestionnaireResult() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sourceQuestionnaire } = state;
  const [questionnaire, setQuestionnaire] = useState<any>();

  useEffect(() => {
    (async () => {
      const result = await apiV1.get(
        `/ext/questionnaire/${sourceQuestionnaire[0].id}/result`
      );
      setQuestionnaire(result);
    })();
  }, [sourceQuestionnaire[0].id]);

  const steps = useMemo(
    () =>
      _.sortBy(questionnaire, (q: any) => q.sortOrder).map((q: any) => ({
        ...q,
        questionnaireQuestionsById: Object.fromEntries(
          q.questionnaireQuestions.map((qq: any) => [qq.id, qq])
        ),
      })),
    [questionnaire]
  );

  return (
    <>
      <TopBanner location={TopBannerLocation.questionnaireResult} />
      <VFlex p-24-rl p-32-b g-16 style={{ maxWidth: 480, width: "100%" }}>
        <HFlex a-c height={60}>
          <SvgIcon
            size={24}
            icon={SvgIconType.Back}
            onClick={() => navigate(-1)}
          />
        </HFlex>
        <Text
          t-20-600
        >{`[${sourceQuestionnaire[0].pet.name}] 미용 서비스 안전문진표`}</Text>
        <Text t-16-g6>
          {format(
            new Date(sourceQuestionnaire[0].actualSentDate * 1000),
            "yyyy.MM.dd"
          )}
          에 작성한 미용 서비스 안전문진표입니다.
        </Text>
        <VFlex g-8 m-32-tb>
          {steps
            .map((item: any) => ({
              title: item.title,
              answer: item.questionnaireResults[0].title,
              note: item.questionnaireResults[0].note,
            }))
            .map(({ title, answer, note }: any, i: number) => (
              <HFlex key={i} p-16 g-16 bc-g9 bdr-24>
                <HFlex c-c d-28 sized>
                  <Text t-24-800-pt>Q</Text>
                </HFlex>
                <VFlex>
                  <Text t-20 l-n>
                    {title}
                  </Text>
                  {answer && (
                    <Text t-20-600-pt l-n m-8-t>
                      {answer}
                    </Text>
                  )}
                  {note && (
                    <Text m-8-t t-20-g5 l-n>
                      {note}
                    </Text>
                  )}
                </VFlex>
              </HFlex>
            ))}
        </VFlex>
      </VFlex>
    </>
  );
}
