import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { toast } from "react-hot-toast";

const clientV1 = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_AUTH_KEY,
  },
  baseURL: process.env.REACT_APP_DATA_API_BASE_URL_V1,
});

const clientV2 = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_AUTH_KEY,
  },
  baseURL: process.env.REACT_APP_DATA_API_BASE_URL_V2,
});

async function handleResponse(res: AxiosResponse) {
  if (res.data.httpStatus === "UNAUTHORIZED") {
    return;
  }

  if (res.data.apiCode === "A01" || res.data.apiCode === "1") {
    return res.data.data;
  }

  console.log(res.data, res.config.url);
  alert(
    `Error: ${res.data.message} (${res.data.apiCode})\nPath: ${res.config.url}`
  );

  return res.data.data;
}

async function handleError(res: AxiosError) {
  if (res.config?.url !== "/ext/admin/bug-report?platform=USER_WEB") {
    if (window.location.href.includes("servicenotereview")) {
      return;
    }
    await apiV1.post("/ext/admin/bug-report?platform=USER_WEB", {
      requestMethod: res.config?.method,
      apiPath: res.config?.url,
      errorType: res.response?.status,
      errorMessage: (res.response?.data as { message: string }).message,
    });
    toast.error("에러가 발생하였습니다. 다시 시도해 주세요.");
  }
}

async function axiosGet<T = any>(
  client: AxiosInstance,
  url: string,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.get(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosPost<T = any>(
  client: AxiosInstance,
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.post(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosPut<T = any>(
  client: AxiosInstance,
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.put(url, data, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

async function axiosDelete<T = any>(
  client: AxiosInstance,
  url: string,
  config?: AxiosRequestConfig
) {
  try {
    const res = await client.delete(url, config);
    return handleResponse(res) as T;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
}

const apiV1 = {
  get: (url: string, config?: AxiosRequestConfig) =>
    axiosGet(clientV1, url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosPost(clientV1, url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) =>
    axiosDelete(clientV1, url, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosPut(clientV1, url, data, config),
};

const apiV2 = {
  get: (url: string, config?: AxiosRequestConfig) =>
    axiosGet(clientV2, url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosPost(clientV2, url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) =>
    axiosDelete(clientV2, url, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosPut(clientV2, url, data, config),
};

export { apiV1, apiV2 };
