import { useCallback, useEffect, useState } from "react";

import { apiV1 } from "../common/api";

import _ from "lodash";
import bluebird from "bluebird";
import { toast } from "react-hot-toast";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Divider from "../layouts/Divider";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

export type Service = {
  id: number;
  parent: number;
  name: string;
};

function ServiceBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (value: Record<string, Service[]>) => void;
}) {
  const [taxonomies, setTaxonomies] = useState<Record<string, any[]>>({});
  const [selectedServices, setSelectedServices] = useState<
    Record<string, Service[]>
  >({});

  useEffect(() => {
    (async () => {
      const taxonomies: Record<string, any[]> = {};
      await bluebird.map(
        [
          "INTANGIBLE_BEAUTY_MENU",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=198",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=199",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=200",
        ],
        async (taxonomyType) => {
          const data = await apiV1.get(`/rest/taxonomy/${taxonomyType}`);
          taxonomies[taxonomyType] = data;
        }
      );
      setTaxonomies(taxonomies);
    })();
  }, []);

  const isCompleted = useCallback(() => {
    return selectedServices["198"]?.length > 0;
  }, [selectedServices]);

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={60}>
        <Text f-1 t-22-600>
          서비스 선택
        </Text>
      </HFlex>
      <Divider m-24-rl />
      <VFlex
        p-16-t
        p-24-rl
        g-32
        height={700}
        style={{ paddingBottom: 220, overflow: "scroll" }}
      >
        {_.entries(taxonomies["INTANGIBLE_BEAUTY_MENU"]).map(
          ([, { id, name }], i) => (
            <VFlex key={i} g-16>
              <Text t-18-600-s6>
                {name}
                <Text m-4-l t-18-gr5>
                  {id === 198 && "*"}
                </Text>
              </Text>
              <HFlex g-8 style={{ flexWrap: "wrap" }}>
                {taxonomies[`INTANGIBLE_BEAUTY_SERVICE?objectId=${id}`]?.map(
                  (item, j) => (
                    <HFlex
                      key={j}
                      c-c
                      height={48}
                      p-22-rl
                      bc-t1
                      bdr-16
                      bd-t1={!selectedServices[id]?.includes(item)}
                      bd-gr5={selectedServices[id]?.includes(item)}
                      t-16-s6={!selectedServices[id]?.includes(item)}
                      t-16-gr5={selectedServices[id]?.includes(item)}
                      style={{
                        boxSizing: "border-box",
                      }}
                      onClick={() => {
                        setSelectedServices({
                          ...selectedServices,
                          [id]: (selectedServices[id] ?? []).includes(item)
                            ? id === 198
                              ? []
                              : selectedServices[id].filter((v) => v !== item)
                            : id === 198
                            ? [item]
                            : [...(selectedServices[id] ?? []), item],
                        });
                      }}
                    >
                      {item.name}
                    </HFlex>
                  )
                )}
              </HFlex>
            </VFlex>
          )
        )}
        <VFlex
          p-16-t
          p-24-rl
          p-40-b
          bc-ww
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000000,
            borderTop: "1px solid #EBEDEC",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <VFlex a-c t-16-s8 height={24} ovf-s>
            {_.flatMap(selectedServices)
              .map((service) => service.name)
              .join(", ")}
          </VFlex>
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            m-12-t
            p-20-tb
            style={{
              opacity: isCompleted() ? 1 : 0.5,
            }}
            onClick={() => {
              if (!isCompleted()) {
                return toast.error("메인 메뉴를 선택해주세요");
              }
              onComplete(selectedServices);
            }}
          >
            저장
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default ServiceBottomSheet;
