import { useState } from "react";
import { useLocation } from "react-router-dom";

import { apiV1 } from "../../common/api";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";
import { useAppLinkContext } from "../../modules/useAppLink";

import AppDownloadBottomSheet from "../AppDownloadBottomSheet";
import { numberWithCommas, calcPercnt } from "./Invoice";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";

import _ from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceDetail() {
  const { trackEvent } = useAmplitudeTrack();
  const { openAppLink } = useAppLinkContext();

  const { state } = useLocation();
  const { title, cardName, installment, easyPayment, invoice, res } =
    state ?? {};
  const [appDownloadBottomSheetOpened, setAppDownloadBottomSheetOpened] =
    useState(true);

  return (
    <VFlex f-1 as-st c-c bc-t2>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
          paddingBottom: 100,
        }}
      >
        <VFlex p-24 g-24>
          <HFlex a-c j-b height={64}>
            <Text t-24-600-s8>결제 내역 상세</Text>
            <SvgIcon size={24} icon={SvgIconType.Close} />
          </HFlex>

          <VFlex bc-ww bdr-24 p-24 p-32-b>
            <VFlex a-c p-24-b g-12>
              <SvgIcon size={44} icon={SvgIconType.InvoiceDetail} />
              <VFlex a-c g-6>
                <Text t-16-s8>{invoice.group.title}</Text>
                <Text t-24-600-s8>
                  {invoice.invoices.map((v: any) => {
                    return numberWithCommas(v.actualAmount);
                  })}
                  원
                </Text>
              </VFlex>
            </VFlex>

            <VFlex g-16 p-20-tb bd-t-t2>
              <HFlex j-b>
                <Text t-18-600-s8>고객 정보</Text>
              </HFlex>
              <VFlex g-8>
                <HFlex j-b>
                  <Text t-16-s4>고객 정보</Text>
                  <Text t-16-s8>
                    {invoice.reservation.pet.name} &#40;
                    {invoice.reservation.pet.species.name}&#41;
                  </Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>예약자 정보</Text>
                  <Text t-16-s8>
                    {invoice.reservation.reserver.nickname
                      ? invoice.reservation.reserver.nickname
                      : invoice.reservation.reserver.name}
                    &nbsp;&#40;{invoice.reservation.reserver.cellnumber}&#41;
                  </Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>예약 일시</Text>
                  <Text t-16-s8>
                    {format(
                      new Date(invoice.reservation.reservationDate * 1000),
                      "yyyy.MM.dd (EEE)",
                      {
                        locale: ko,
                      }
                    )}
                  </Text>
                </HFlex>
              </VFlex>
            </VFlex>

            <VFlex g-16 p-20-tb bd-tb-t2>
              <HFlex j-b>
                <Text t-18-600-s8>서비스 정보</Text>
              </HFlex>
              <VFlex g-8>
                {invoice.reservation.products.map(
                  (product: {
                    id: number;
                    name: string;
                    quantity: number;
                    price: number;
                  }) => {
                    return (
                      <HFlex j-b key={product.id}>
                        <HFlex g-8>
                          <Text t-16-s4>{product.name}</Text>
                          <Text t-16-s2>&#215;{product.quantity}</Text>
                        </HFlex>
                        <Text t-16-s8>{numberWithCommas(product.price)}원</Text>
                      </HFlex>
                    );
                  }
                )}
              </VFlex>
            </VFlex>

            <VFlex g-16 p-20-tb>
              <Text t-18-600-s8>결제 정보</Text>
              <VFlex g-8>
                <HFlex j-b>
                  <Text t-16-s4>서비스 합계</Text>
                  <Text t-16-s8>
                    {invoice.invoices.map((v: any) => {
                      return numberWithCommas(v.totalAmount);
                    })}
                    원
                  </Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>적립금 사용</Text>
                  <Text t-16-s8>0원</Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>가격 할인</Text>
                  <HFlex t-16-s8>
                    <Text>
                      {invoice.invoices.map((v: any) => {
                        return v.discountRate === 0
                          ? invoice.invoices.map((v: any) => {
                              return v.discountAmount;
                            })[0] <= 0
                            ? numberWithCommas(v.discountAmount)
                            : `-${numberWithCommas(v.discountAmount)}`
                          : invoice.invoices.map((v: any) => {
                              return `-${numberWithCommas(
                                calcPercnt(v.totalAmount, v.discountRate)
                              )}`;
                            });
                      })}
                      원
                    </Text>
                    {invoice.invoices.map((v: any, i: number) => {
                      return v.discountRate <= 0 ? null : (
                        <Text key={i}>&#40;{v.discountRate}%&#41;</Text>
                      );
                    })}
                  </HFlex>
                </HFlex>
                {invoice.invoices.map((v: any) => {
                  return v.extraAmount;
                })[0] > 0 && (
                  <HFlex j-b>
                    <Text t-16-s4>가격 추가</Text>
                    <Text t-16-s8>
                      +
                      {invoice.invoices.map((v: any) => {
                        return numberWithCommas(v.extraAmount as 0);
                      })}
                      원
                    </Text>
                  </HFlex>
                )}
                <HFlex j-b t-18-600-pt>
                  <Text>결제 금액</Text>
                  <Text>
                    {invoice.invoices.map((v: any) => {
                      return numberWithCommas(v.actualAmount);
                    })}
                    원
                  </Text>
                </HFlex>
                {!!invoice.group.mileagePolicy && (
                  <HFlex j-b p-12-b>
                    <Text t-16-s4>
                      적립 금액&#40;
                      {
                        invoice.group.mileagePolicy.filter(
                          (mileage: any) => mileage.type === "MILEAGE_CARD"
                        )[0].value
                      }
                      %&#41;
                    </Text>
                    <Text t-16-s8>
                      {numberWithCommas(
                        calcPercnt(
                          invoice.invoices.map((v: any) => v.actualAmount)[0],
                          Number(
                            invoice.group.mileagePolicy.filter(
                              (v: any) => v.type === "MILEAGE_CARD"
                            )[0].value
                          )
                        )
                      )}
                      원
                    </Text>
                  </HFlex>
                )}
                <HFlex j-b bd-t-t1 p-20-t>
                  <Text t-16-s4>결제 일시</Text>
                  <Text t-16-s8>
                    {format(new Date(res.paidTime * 1000), "yyyy.MM.dd HH:MM", {
                      locale: ko,
                    })}
                  </Text>
                </HFlex>
                <HFlex j-b>
                  <Text t-16-s4>결제 카드</Text>
                  {/* <Text t-16-s8>{cardName ? cardName : easyPayment} ****1234 개인</Text> */}
                  <Text t-16-s8>
                    {res.cardCode.name} {res.cardNumber}
                  </Text>
                </HFlex>
              </VFlex>
            </VFlex>

            <VFlex
              a-c
              bd-t3
              bdr-12
              p-12
              t-16-s8
              m-16-t
              onClick={() => {
                // TODO
              }}
            >
              영수증 보기
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>

      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          onClick={() => {
            trackEvent(amplitudeEvents.appDownloadBtnClick, {
              location: "invoice_detail",
            });
            openAppLink("invoice_detail_to_check_app");
          }}
        >
          <HFlex a-c g-4>
            <Text>앱에서 확인하기</Text>
            <SvgIcon size={24} icon={SvgIconType.AppDownW} />
          </HFlex>
        </VFlex>
      </VFlex>

      <AppDownloadBottomSheet
        open={appDownloadBottomSheetOpened}
        onDismiss={() => setAppDownloadBottomSheetOpened(false)}
      />
    </VFlex>
  );
}
