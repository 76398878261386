import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { apiV1 } from "../../common/api";
import { formatPhoneNumber } from "../../common/utils/format";

import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import TextInput from "../../layouts/TextInput";

import PhoneNumberBottomSheet from "../PhoneNumberBottomSheet";
import PetSelectBottomSheet from "../PetSelectBottomSheet";

declare global {
  var IMP: any;
}

export default function LinkReservation(props: any) {
  const [searchParams] = useSearchParams();
  let key = searchParams.get("key");
  if (key) {
    key = key.replaceAll("/", "");
  }

  useEffect(() => {
    var IMP = window.IMP;
    IMP.init("imp08477488");
  }, []);

  const [group, setGroup] = useState<{
    id: number;
    title: string;
  }>();

  useEffect(() => {
    (async () => {
      // test key : wWe5k
      if (key && key.includes("?")) {
        key = key.split("?")[0];
      }
      const group = await apiV1.get(
        `/ext/group/information?key=${key}&type=G_INQ_RESV_LINK`
      );
      setGroup(group);
    })();
  }, [key]);

  const navigate = useNavigate();
  const [phoneNumberBottomSheetOpened, setPhoneNumberBottomSheetOpened] =
    useState(false);
  const [petSelectBottomSheetOpened, setPetSelectBottomSheetOpened] =
    useState(false);
  const [phone, setPhone] = useState("");
  const [petValue, setPetValue] = useState<any>();

  const isCompleted = useCallback(() => {
    return phone.length === 13;
  }, [phone]);

  return (
    <VFlex
      f-1
      p-24-rl
      p-16-b
      style={{ maxWidth: 480, width: "100%", paddingTop: 60 }}
    >
      <Text m-16-tb t-24-600 l-n>
        고객 확인
      </Text>
      <Text t-14-g5 f-1>
        고객 확인 및 예약 안내를 위해 휴대폰 번호를 입력해주세요
      </Text>
      <Text t-14-g5 f-1 m-32-t m-4-b>
        업체명
      </Text>
      <Text t-18 f-1>
        {group?.title}
      </Text>
      <div
        style={{
          marginTop: "32px",
          width: "100%",
          height: "1px",
          background: "#F1F3F2",
        }}
      ></div>
      <Text t-14-g5 f-1 m-32-t m-4-b>
        휴대폰 번호
      </Text>
      <TextInput
        placeholder="휴대폰 번호를 입력해주세요"
        value={phone}
        onChangeValue={(value) =>
          setPhone((phone) => formatPhoneNumber(phone, value) ?? "")
        }
      />

      <VFlex
        c-c
        height={64}
        bdr-24
        t-18-ww
        bc-pt
        style={{ opacity: isCompleted() && group ? 1 : 0.5, marginTop: 116 }}
        onClick={() => {
          if (!group) {
            return;
          }
          if (!isCompleted()) return alert("휴대폰 번호를 입력해주세요");
          setPhoneNumberBottomSheetOpened(true);
        }}
      >
        확인
      </VFlex>

      <PhoneNumberBottomSheet
        number={phone}
        open={phoneNumberBottomSheetOpened}
        onDismiss={() => setPhoneNumberBottomSheetOpened(false)}
        onComplete={async () => {
          setPhoneNumberBottomSheetOpened(false);
          window.IMP.certification(
            {
              merchant_uid: "merchant_" + new Date().getTime(),
              phone,
            },
            async (rsp: any) => {
              if (rsp.success) {
                const res = await apiV1.get(
                  `/ext/pets?cellNumber=${phone}&groupId=${group?.id}&impUid=${rsp.imp_uid}`
                );

                if (res.length > 0) {
                  // 기존 고객
                  setPetSelectBottomSheetOpened(true);
                  setPetValue(res);
                } else {
                  // 신규 고객
                  navigate("/linkreservationstep", {
                    state: {
                      group: {
                        id: group?.id,
                        title: group?.title,
                      },
                      imp_uid: rsp.imp_uid,
                      phone: phone,
                      type: "P_LINK_N",
                    },
                  });
                }
              } else {
                alert("본인인증에 실패하였습니다.");
              }
            }
          );
        }}
      />

      <PetSelectBottomSheet
        value={petValue}
        open={petSelectBottomSheetOpened}
        onComplete={(value) => {
          setPetSelectBottomSheetOpened(false);

          // 기존 고객 -> 반려동물 선택 후 스케줄로 이동
          navigate(`/schedule`, {
            state: {
              group: {
                id: group?.id,
                title: group?.title,
              },
              pet: {
                id: value.id,
                name: value.name,
              },
              subscriber: {
                id: value.guardians[0].id,
              },
              type: "P_LINK",
            },
          });
        }}
      />
    </VFlex>
  );
}
